import { useMutation } from '@tanstack/react-query';
import Stepper from '../../--components/--stepper'
import React, { useContext, useEffect, useState } from 'react'
import useSva from '../../../utils/utilities/hooks/useSva';
import toast from 'react-hot-toast';
import { Link, useNavigate } from 'react-router-dom';
import { City } from "country-state-city";
import { AuthContext } from '../../../context/auth';
import useImage from '../../../utils/utilities/hooks/useImage';
import { Image } from '../../../sdks/GlobalDataSchemas';
import { TrophySpin } from 'react-loading-indicators';
function ReportSva() {
  const { sessionInfo, setUserInfo } = useContext(AuthContext)
  const [sucess, setSuccess] = useState<boolean>(false)

  

  const [full_name, setFullName] = useState("");
  const [device_name, setDeviceName] = useState("");
  const [serial_number, setSerialNumber] = useState("");
  const [description, setDescription] = useState("");
  const [order_id, seOrderId] = useState("");
  const [email_address, setEmailadress] = useState("");
  const [loadImageUpload, setLoadImageUpload] = useState(false);
  
  const [phone_number, setPhonenumber] = useState("");
  const [images, setImages] = useState<any>([]);
  let { client } = useSva()
  let { client: imageClient } = useImage()
  const navigate =  useNavigate()
  const mutation = useMutation({
    mutationFn: async () => {
      return await client.createSva({
        device_name,
        order_id,
        serial_number,
        description,
        images,
        full_name,
        email_address,
        phone_number,
        is_new: true,
        status: 0
      });
      
    },
    onSuccess: (data, variables, context) => {
      setFullName("");
      setDeviceName("");
      setSerialNumber("");
      setDescription("");
      seOrderId("");
      setEmailadress("");
      setPhonenumber("");
      setSuccess(true)
    },
    onError: (data: any) => {
      toast.error(data?.message , {position: 'bottom-center'})
    },
  });
  
  const handleFileInputChange = async (e: any) => {
    const files = e.target.files;
    setLoadImageUpload(true)
    try {
      const result = await imageClient.uploadImage({image: files[0], folder: 'svas'})
      setImages((old: Image[]) => [...old, result]);
    } catch (error) {
      toast.error('Veuillez réessayer avec une image de moins de 1mb' , {position: 'bottom-center'})
      
    }
    setLoadImageUpload(false)
  };

  const removeImage = async (image: any) => {
    setLoadImageUpload(true)
    try {
        await imageClient.deleteImage(image.public_id)
        const remainImages = images.filter((im: any) => im?.public_id !== image.public_id)
        setImages(remainImages)
    } catch (error) {

      toast.error('Veuillez réessayer' , {position: 'bottom-center'})
      
    }
    setLoadImageUpload(false)
  };

  useEffect(() => {
    if(sessionInfo?.userInfo) {
      setFullName(sessionInfo?.userInfo?.full_name)
      setPhonenumber(sessionInfo?.userInfo?.phone_numbers[0])
      setEmailadress(sessionInfo?.userInfo?.email_address)
    }
  }, [sessionInfo])

  return (
    <div className='--cart-page w-full flex justify-center' >
        <div className="wrapper">
        
          <div id="contents" role="main" className="col-lg-12 col-md-12 col-sm-12">
              <div className="post-300 page type-page status-publish hentry">
                
                <div className="entry-content">
                  <div className="woocommerce">
                    <div className="woocommerce-notices-wrapper" />
                    <div className="col2-set row" id="customer_login">
                      <div className="col-lg-6 col-md-6 col-sm-12">
                        {sucess ? <h2>Appareil enregistré pour le serviec après vente !</h2> : <h2>Reporter un SAV</h2>}
                        {!sucess ? <form className="woocommerce-form woocommerce-form-login login" method="post">
                          <div className="w-full flex flex-col width600:flex-row items-center">
                            <p className="form-row form-row-wide">
                              <label htmlFor="lastname">Nom de l'appareil <span className="required">*</span></label>
                              <input 
                              value={device_name}
                              onChange={(e) => setDeviceName(e.target.value)}
                              type="text" className="input-text" name="lastname" id="lastname" />
                            </p>
                            <p className="form-row form-row-wide">
                              <label htmlFor="firtsname">Numéro de série de l'appareil <span className="required">*</span></label>
                              <input 
                              value={serial_number}
                              onChange={(e) => setSerialNumber(e.target.value)}
                              type="text" className="input-text" name="firtsname" id="firtsname" />
                            </p>
                            
                            
                          </div>

                          <div className="w-full flex flex-col width600:flex-row items-center">
                            <p className="form-row form-row-wide">
                              <label htmlFor="lastname">Numéro de commande (Si l'appreil provient d'une commande chez nous)</label>
                              <input 
                              value={order_id}
                              onChange={(e) => seOrderId(e.target.value)}
                              type="text" className="input-text" name="lastname" id="lastname" />
                            </p>
                            <p className="form-row form-row-wide">
                              <label htmlFor="lastname">Votre nom et prénom(s) <span className="required">*</span></label>
                              <input 
                              value={full_name}
                              onChange={(e) => setFullName(e.target.value)}
                              type="text" className="input-text" name="lastname" id="lastname" />
                            </p>
                            
                          </div>

                          <div className="w-full flex flex-col width600:flex-row items-center">
                            <p className="form-row form-row-wide">
                              <label htmlFor="lastname">Adresse email</label>
                              <input 
                              value={email_address}
                              onChange={(e) => setEmailadress(e.target.value)}
                              type="text" className="input-text" name="lastname" id="lastname" />
                            </p>
                            <p className="form-row form-row-wide">
                              <label htmlFor="lastname">Numéro de téléphone <span className="required">*</span></label>
                              <input 
                              value={phone_number}
                              onChange={(e) => setPhonenumber(e.target.value)}
                              type="text" className="input-text" name="lastname" id="lastname" />
                            </p>
                          </div>

                          <p className="form-row form-row-wide">
                              <label htmlFor="lastname">Décrivez le disfonctionnement que vous constatez</label>
                              <textarea 
                              value={description}
                              onChange={(e: any) => setDescription(e.target.value)}
                              name="order_comments" className=" min-h-[100px] p-3 input-text resize-none" id="order_comments" placeholder="e.g. commentaire spécial sur ma commande." rows={2} cols={5} defaultValue={""} />
                            </p>

                          

                          <div className="my-2 grid grid-cols-8 gap-5">

                            {images &&
                            images.map((i: any) => (
                              <span className="relative inline-block h-40 w-40 overflow-hidden">
                                
                                  <img
                                    key={i?.url}
                                    src={i?.url}
                                    alt="avatar"
                                    className="h-full w-full object-cover "
                                  />
                                  
                                  { (images.length > 1) ? <span 
                                  onClick={() => removeImage(i)}
                                  className="absolute shadow top-3 right-3 w-6 h-6 bg-red-600 rounded-full cursor-pointer flex items-center justify-center" >
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width={16} height={16} color={"#ffffff"} fill={"none"}>
                                        <path d="M14.9994 15L9 9M9.00064 15L15 9" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22C17.5228 22 22 17.5228 22 12Z" stroke="currentColor" strokeWidth="1.5" />
                                    </svg>
                                  </span>: <></>}
                              
                              </span>
                            ))}

                            <label htmlFor="uploadFile1" className="bg-white text-gray-500 p-3 font-semibold text-base rounded w-40 h-40 flex flex-col items-center justify-center cursor-pointer border-2 border-gray-300 border-dashed font-[sans-serif]">
                              <svg xmlns="http://www.w3.org/2000/svg" className="w-11 mb-2 fill-gray-500" viewBox="0 0 32 32">
                                <path d="M23.75 11.044a7.99 7.99 0 0 0-15.5-.009A8 8 0 0 0 9 27h3a1 1 0 0 0 0-2H9a6 6 0 0 1-.035-12 1.038 1.038 0 0 0 1.1-.854 5.991 5.991 0 0 1 11.862 0A1.08 1.08 0 0 0 23 13a6 6 0 0 1 0 12h-3a1 1 0 0 0 0 2h3a8 8 0 0 0 .75-15.956z" data-original="#000000" />
                                <path d="M20.293 19.707a1 1 0 0 0 1.414-1.414l-5-5a1 1 0 0 0-1.414 0l-5 5a1 1 0 0 0 1.414 1.414L15 16.414V29a1 1 0 0 0 2 0V16.414z" data-original="#000000" />
                              </svg>
                              {loadImageUpload ? <TrophySpin color="#001b97" size="small" text="" textColor="" /> :  'Upload file'}
                              <input type="file" id="uploadFile1" className="hidden" 
                                onChange={handleFileInputChange}/>
                              <p className="text-xs text-center font-medium text-gray-400 mt-2">PNG, JPG SVG, WEBP, and GIF are Allowed.</p>
                            </label>

                          </div>
                          

                          <p className="form-row">
                            <input 
                            disabled={mutation?.isPending
                              ||
                              (
                                device_name.trim() === "" ||
                                description.trim() === "" ||
                                full_name.trim() === "" ||
                                email_address.trim() === "" ||
                                phone_number.trim() === ""
                              )
                            }
                            onClick={() => {
                              !mutation.isPending && mutation.mutate()
                            }}
                            type="button" className="button" defaultValue={!mutation.isPending ? "Enregistrer" : "Loading..."} /> 
                          </p>
                          
                        </form> : <p>Votre appareil a été enregistré avec succès. Nos équipes le traiteront dans les plus brefs délais.</p>}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="comment-single">
                </div>			
              </div>
          </div>

        </div>
        
      </div>
  )
}

export default ReportSva