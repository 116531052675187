import moment from 'moment';
import { AuthContext } from '../../../../context/auth';
import useOrder from '../../../../utils/utilities/hooks/useOrder';
import { useMutation, useQuery } from '@tanstack/react-query';
import React, { useContext, useState } from 'react'
import { moneyFormating } from '../../../../utils/utilities/constants';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Order } from '../../../../sdks/order-v1/utils/DataSchemas';
import { Commet, Riple } from 'react-loading-indicators';
import EmptyIllustration from '../../../../layouts/--partials/--empty-illustration';
import toast from 'react-hot-toast';

function OrderDetails() {

  let { client } = useOrder()
  const navigate = useNavigate()
  const {id} = useParams()
  const { sessionInfo, setUserInfo, signOut } = useContext(AuthContext)
  const [currentData, setCurrentData] = useState<Order>();
  const [isLoading, setLoading] = useState<boolean>(true);

  const updateMutation = useMutation({
    mutationFn: async () => {
      if(currentData) {
        return await client.updateUserOrderStatus(currentData?._id, 'cancelled');
      }
      
    },
    onSuccess: () => {
      toast("Commande annulée avec succès !", {
        duration: 4000,
        position: 'top-center',
      
        // Styling
        style: {},
        className: '',
      
        // Custom Icon
        icon: '🥺',
      
        // Change colors of success/error/loading icon
        iconTheme: {
          primary: '#000',
          secondary: '#fff',
        },
      
        // Aria
        ariaProps: {
          role: 'status',
          'aria-live': 'polite',
        },
      });

      setTimeout(() => {
          navigate('/profile/orders')
      }, 500);
    },
    onError: (data: any) => {
      toast.error(data?.message , {position: 'bottom-center'})
    },
  });
  
  useQuery({
    queryKey: ['order-details-data', id],
    queryFn: async () => {
        if(id) {
          let result = await client.getOrderById(id)
          setCurrentData(result)
          setLoading(false)
        }
    }
  })

  if(isLoading) {
    return <Commet color="#001c97db" size="small" text="Recherche" textColor="#fff" />
  }

  const getStatusLabel = (status: string) => {
    
    switch (status) {

      case 'pending':
        return 'En attente';
        break;

      case 'processing':
        return 'En traitement';
        break;

      case 'shipping':
        return 'En cours de livraison';
        break;

      case 'refund-request':
        return 'Processus de retour';
        break;

      case 'refunded':
        return 'Remboursé';
        break;

      case 'cancelled':
        return 'Annulé';
        break;
        
      case 'not-delivered':
        return 'Echec de livraison';
        break;
      case 'delivered':
        return 'Livré';
        break;
    
      default:
        break;
    }
  }

  

  return (
    <>
    {currentData ? <>
        <p>
          Commande #<mark className="order-number">{currentData?._id}</mark> a été éffectuée le <mark className="order-date">{moment(currentData?.created_at).format('DD MMMM YYYY hh:m:s')}</mark> et est en l'état suivant: <mark className="order-status">{getStatusLabel(currentData?.status)}</mark>.</p>
        <section className="order-details">
          <h2 className="order-details__title">Détails de la commande</h2>
          <table className="table table--order-details shop_table order_details">
            <thead>
              <tr>
                <th className="table__product-name product-name">Produit</th>
                <th className="table__product-table product-total">Total</th>
              </tr>
            </thead>
            <tbody>

              {currentData?.cart?.map((c: any, index: number) => {
                return <tr key={index} className="table__line-item order_item">
                        <td className="table__product-name product-name flex">
                          <p className='max-w-[200px] width500:max-w-[300px] bigTablet:max-w-[600px] truncate text-ellipsis'>{c?.title}&nbsp;</p> <strong className="product-quantity">×&nbsp;{c?.qty}</strong>
                        </td>
                        <td className="table__product-total product-total"> <span className="Price-amount amount" >{moneyFormating(c?.promotion?.active ? c?.promotion?.cost : c?.price)}</span></td>
                      </tr>
                    })
                }
            </tbody>
            <tfoot>
              <tr>
              <th scope="row">Sous total:</th>
              <td><span className="Price-amount amount"  >{moneyFormating(currentData?.total_price ?? 0)}</span></td>
              </tr>
              <tr>
                  <th scope="row">Frais de livraison:</th>
                  <td><span className="Price-amount amount"  >{moneyFormating(currentData?.shipping_cost ?? 0)}</span></td>
                </tr>
              <tr>
                <th scope="row">Methode de paiement:</th>
                <td>Cash à la livraison</td>
              </tr>
              <tr>
                  <th scope="row">Total:</th>
                  <td><span className="Price-amount amount" >{moneyFormating(currentData?.total_price ?? 0 + currentData?.shipping_cost ?? 0)}</span></td>
              </tr>
            </tfoot>
          </table>
        </section>
        <section className="customer-details">
        <h2 className="column__title">Adresse de livraison</h2>
          <address>
              {currentData?.user?.full_name}<br />
              {currentData?.shipping_address?.full_address}<br />
              {currentData?.shipping_address?.city}<br />
              Côte d'Ivoire
              <p className="customer-details--phone">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="16" height="16" color="#444444" fill="none">
                    <path d="M5 9C5 5.70017 5 4.05025 6.02513 3.02513C7.05025 2 8.70017 2 12 2C15.2998 2 16.9497 2 17.9749 3.02513C19 4.05025 19 5.70017 19 9V15C19 18.2998 19 19.9497 17.9749 20.9749C16.9497 22 15.2998 22 12 22C8.70017 22 7.05025 22 6.02513 20.9749C5 19.9497 5 18.2998 5 15V9Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
                    <path d="M11 19H13" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M9 2L9.089 2.53402C9.28188 3.69129 9.37832 4.26993 9.77519 4.62204C10.1892 4.98934 10.7761 5 12 5C13.2239 5 13.8108 4.98934 14.2248 4.62204C14.6217 4.26993 14.7181 3.69129 14.911 2.53402L15 2" stroke="currentColor" strokeWidth="1.5" strokeLinejoin="round" />
                </svg>
                {currentData?.user?.phone_numbers && currentData?.user?.phone_numbers[0]} {currentData?.shipping_address?.phone_number ? ` / ${currentData?.shipping_address?.phone_number}` : ''} </p>
                <p className="customer-details--email">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="16" height="16" color="#444444" fill="none">
                    <path d="M2 6L8.91302 9.91697C11.4616 11.361 12.5384 11.361 15.087 9.91697L22 6" stroke="currentColor" strokeWidth="1.5" strokeLinejoin="round" />
                    <path d="M2.01577 13.4756C2.08114 16.5412 2.11383 18.0739 3.24496 19.2094C4.37608 20.3448 5.95033 20.3843 9.09883 20.4634C11.0393 20.5122 12.9607 20.5122 14.9012 20.4634C18.0497 20.3843 19.6239 20.3448 20.7551 19.2094C21.8862 18.0739 21.9189 16.5412 21.9842 13.4756C22.0053 12.4899 22.0053 11.5101 21.9842 10.5244C21.9189 7.45886 21.8862 5.92609 20.7551 4.79066C19.6239 3.65523 18.0497 3.61568 14.9012 3.53657C12.9607 3.48781 11.0393 3.48781 9.09882 3.53656C5.95033 3.61566 4.37608 3.65521 3.24495 4.79065C2.11382 5.92608 2.08114 7.45885 2.01576 10.5244C1.99474 11.5101 1.99475 12.4899 2.01577 13.4756Z" stroke="currentColor" strokeWidth="1.5" strokeLinejoin="round" />
                </svg>
                {currentData?.user?.email_address}</p>
          </address>
        </section>
        { currentData?.status === 'pending'  && <p className='mt-5 order-details-action'>
          <button type="button" 
          onClick={() => !updateMutation.isPending && updateMutation.mutate()}
          
          className="Button button wp-element-button" name="save_account_details" >{updateMutation?.isPending ? <Riple color="#000" size="small" text="Loading..." textColor="" /> : 'Annuler la commande'}</button>
        </p>}
    </>: <EmptyIllustration/>}
    </>
  )
}

export default OrderDetails