import { Link, useNavigate } from 'react-router-dom'
import Stepper from '../../--components/--stepper'
import React, { useContext, useEffect, useState } from 'react'
import userUser from '../../../utils/utilities/hooks/useAuth';
import toast from 'react-hot-toast';
import { useMutation } from '@tanstack/react-query';
import { AuthContext } from '../../../context/auth';

function PassForgot() {

  const { sessionInfo, setUserInfo, forgotPassword } = useContext(AuthContext)
  const [email_address, setEmailadress] = useState("");
  const [sucess, setSuccess] = useState<boolean>(false)
  let { client } = userUser()
  const navigate =  useNavigate()

  const mutation = useMutation({
    mutationFn: async () => {
      return await forgotPassword({
        email_address
      });
      
    },
    onSuccess: (data, variables, context) => {
      setEmailadress('')
      setSuccess(true)
    },
    onError: (data: any) => {
      toast.error(data?.message , {position: 'bottom-center'})
    },
  });

  return (
    <div className='--cart-page w-full flex justify-center' >
        <div className="wrapper">
        
          <div id="contents" role="main" className="col-lg-12 col-md-12 col-sm-12">
              <div className="post-300 page type-page status-publish hentry">
                <div className="entry-content">
                  <div className="woocommerce">
                    <div className="notices-wrapper" />
                    <div className="col2-set row" id="customer_login">
                      <div className="col-lg-6 col-md-6 col-sm-12">
                        {sucess ? <h2>Lien de récupération envoyé !</h2> : <h2>Récupérer votre compte ?</h2>}

                        {!sucess && <p className='font-semibold mb-10 ' >Veuillez renseigner votre adresse email sur laquelle vous recevrez un lien de réinitialisation</p>}
                        {!sucess ? <form className="form form-login login" method="post">
                          <p className="form-row form-row-wide">
                            <label htmlFor="username">Adresse email <span className="required">*</span></label>
                            <input 
                            value={email_address}
                            onChange={(e) => setEmailadress(e.target.value)}
                            type="text" className="input-text" name="username" id="username" />
                          </p>
                          
                          <p className="form-row">
                          <input 
                            disabled={mutation?.isPending
                              ||
                              (
                                email_address.trim() === ""
                              )
                          }
                            onClick={() => {
                              !mutation.isPending && mutation.mutate()
                            }}
                            type="button" className="button" defaultValue={!mutation.isPending ? "Envoyer" : "Loading..."} /> 
                          </p>
                          <p className="font-semibold">
                            Je me souviens de mes coordonnées, je veux <Link to="/login" className='font-bold text-[#001c96]'>me connecter</Link>
                          </p>
                        </form>: <p>Nous vous avons envoyé un lien de récupération par email. Veuillez consulter votre boîte de messagérie.</p>}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
          </div>

        </div>
        
      </div>
  )
}

export default PassForgot