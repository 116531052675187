export const IMAGE_ACCEPT = {
    'image/jpeg': ['.jpg', '.jpeg'],
    'image/png': ['.png'],
    'image/webp': ['.webp'],
    'image/svg': ['.svg'],
}
export const API_SERVER = `${process.env.REACT_APP_API_URL}/api/v1`;

export function removeUnnecessaryHTMLStuff(text: string): string {
    let parser = new DOMParser();
    let doc = parser.parseFromString(text, "text/html");
    return doc?.body?.textContent || "";
}

export function moneyFormating (money: number) {
    let formattedAmount = (money ?? 0).toLocaleString('fr-FR', {
        style: 'currency',
        currency: 'XOF', // You can use 'XAF' for Central African CFA
        minimumFractionDigits: 0 // CFA generally doesn't use decimal points
    });
    
    // Remove the symbol if you only want the number and currency abbreviation
    return formattedAmount.replace('F CFA', 'F');
}