import { useMutation } from '@tanstack/react-query';
import Stepper from '../../--components/--stepper'
import React, { useContext, useEffect, useState } from 'react'
import userUser from '../../../utils/utilities/hooks/useAuth';
import toast from 'react-hot-toast';
import { Link, useNavigate } from 'react-router-dom';
import { City } from "country-state-city";
import { AuthContext } from '../../../context/auth';
function Register() {
  const { sessionInfo, setUserInfo } = useContext(AuthContext)
  const [sucess, setSuccess] = useState<boolean>(false)
  const [first_name, setFirstname] = useState("");
  const [last_name, setLastname] = useState("");
  const [email_address, setEmailadress] = useState("");
  const [location, setLocation] = useState({
      full_address: 'Plateau Immeuble Media – rue du commerce',
      city: 'Abidjan'
  });
  const [password, setPassword] = useState("");
  const [confirm_password, setConfirmpassword] = useState("");
  const [phone_number, setPhonenumber] = useState("");
  const [role, _] = useState("user");
  const [gender, setGender] = useState("man");
  const [action, __] = useState("free");
  const [view_pass, setViewwpass] = useState(false);
  let { client } = userUser()
  const navigate =  useNavigate()
  const mutation = useMutation({
    mutationFn: async () => {
      return await client.register({
        first_name,
        last_name,
        email_address,
        location,
        password,
        phone_numbers: [phone_number],
        role,
        gender,
        action
      });
      
    },
    onSuccess: (data, variables, context) => {
      setFirstname('')
      setLastname('')
      setEmailadress('')
      setLocation({
        full_address: 'Plateau Immeuble Media – rue du commerce',
        city: 'Abidjan'
      })
      setPassword('')
      setPhonenumber('')
      setGender('m')
      setSuccess(true)
    },
    onError: (data: any) => {
      toast.error(data?.message , {position: 'bottom-center'})
    },
  });
  

  useEffect(() => {
    if(sessionInfo?.userInfo) {
      navigate('/')
    }
  }, [sessionInfo])

  return (
    <div className='--cart-page w-full flex justify-center' >
        <div className="wrapper">
        
          <div id="contents" role="main" className="col-lg-12 col-md-12 col-sm-12">
              <div className="post-300 page type-page status-publish hentry">
                
                <div className="entry-content">
                  <div className="woocommerce">
                    <div className="woocommerce-notices-wrapper" />
                    <div className="col2-set row" id="customer_login">
                      <div className="col-lg-6 col-md-6 col-sm-12">
                        {sucess ? <h2>Inscription réussie !</h2> : <h2>S'inscrire</h2>}
                        {!sucess ? <form className="woocommerce-form woocommerce-form-login login" method="post">
                          <div className="w-full flex flex-col width600:flex-row items-center">
                            <p className="form-row form-row-wide">
                              <label htmlFor="lastname">Nom <span className="required">*</span></label>
                              <input 
                              value={last_name}
                              onChange={(e) => setLastname(e.target.value)}
                              type="text" className="input-text" name="lastname" id="lastname" />
                            </p>
                            <p className="form-row form-row-wide">
                              <label htmlFor="firtsname">Prénom(s) <span className="required">*</span></label>
                              <input 
                              value={first_name}
                              onChange={(e) => setFirstname(e.target.value)}
                              type="text" className="input-text" name="firtsname" id="firtsname" />
                            </p>
                          </div>
                          <p className="form-row form-row-wide">
                              <label htmlFor="email">Adresse email <span className="required">*</span></label>
                              <input 
                              value={email_address}
                              onChange={(e) => setEmailadress(e.target.value)}
                              type="text" className="input-text" name="email" id="email" />
                          </p>

                          <div className="w-full flex flex-col width600:flex-row items-center">
                            <p className="form-row form-row-wide">
                              <label htmlFor="gender">Genre <span className="required">*</span></label>
                              <select
                              className="input-text w-full ease-linear transition-all duration-150"
                              value={gender}
                              onChange={(e) => setGender(e.target.value)}
                              >
                                <option value={"man"}>Homme</option>
                                <option value={"woman"}>Femme</option>
                              </select>
                            </p>
                            <p className="form-row form-row-wide">
                              <label htmlFor="phone">Numéro de téléphone <span className="required">*</span></label>
                              <input 
                              value={phone_number}
                              onChange={(e) => setPhonenumber(e.target.value)}
                              type="text" className="input-text" name="phone" id="phone" />
                            </p>
                            <p className="form-row form-row-wide">
                              <label htmlFor="username">City <span className="required">*</span></label>
                              <select name="city" id="city" 
                                className="input-text w-full ease-linear transition-all duration-150" 
                                value={location.city}
                                onChange={(e: any) => {
                                  setLocation({...location, city: e.target.value})
                                }}
                                >
                                  <option value=''>Selectionner la ville... </option>
                                  {(City && City.getCitiesOfCountry("CI")?.length) &&
                                  City.getCitiesOfCountry("CI")?.map((item) => (
                                    <option key={item.stateCode} value={item.name}>
                                      {item.name}
                                    </option>
                                  ))}
                                </select>
                            </p>
                          </div>
                          <p className="form-row form-row-wide">
                              <label htmlFor="fulladress">Adresse complète <span className="required">*</span></label>
                              <input 
                              value={location.full_address}
                              onChange={(e) => setLocation({...location, full_address: e.target.value})}
                              type="text" className="input-text" name="fulladress" id="fulladress" />
                          </p>
                          <div className="w-full flex flex-col width600:flex-row items-center">
                          <p className="form-row form-row-wide">
                            <label htmlFor='password' className='flex items-center'> Mot de passe  <span className="required">*</span></label>
                            <input 
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            className="input-text" type={ view_pass ? "text" : "password"} name="password" id="password" />
                          </p>
                          <p className="form-row form-row-wide">
                            <label htmlFor="passwordConf">Confirmation du mot de passe <span className="required">*</span></label>
                            <input
                            value={confirm_password}
                            onChange={(e) => setConfirmpassword(e.target.value)}
                            className="input-text" type={ view_pass ? "text" : "password"} name="password" id="password" />
                          </p>
                          </div>
                          
                          <p className="form-row">
                            <input 
                            disabled={mutation?.isPending
                              ||
                              (
                                password.trim() === "" ||
                                confirm_password.trim() === "" ||
                                first_name.trim() === "" ||
                                last_name.trim() === "" ||
                                email_address.trim() === "" ||
                                phone_number.trim() === "" ||
                                gender.trim() === "" ||
                                location?.full_address?.trim() === "" ||
                                location?.city?.trim() === ""
                              )
                          }
                            onClick={() => {
                              if(password !== confirm_password) {
                                toast.error("Les mots de passe sont différents" , {position: 'bottom-center'})
                              }else {
                                !mutation.isPending && mutation.mutate()
                              }
                            }}
                            type="button" className="button" defaultValue={!mutation.isPending ? "Inscription" : "Loading..."} /> 
                          </p>
                          <p className="font-semibold">
                            J'ai déjà un compte, je veux <Link to="/login" className='font-bold text-[#001c96]'>me connecter</Link>
                          </p>
                        </form> : <p>Votre insciption a été éffectuée avec succès. Nous vous avons envoyé un lien d'activation par email. Veuillez consulter votre boîte de messagérie.</p>}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="comment-single">
                </div>			
              </div>
          </div>

        </div>
        
      </div>
  )
}

export default Register