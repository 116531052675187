import Stepper from '../../--components/--stepper'
import { Link, useNavigate } from 'react-router-dom';
import React, { useContext, useEffect, useState } from 'react'
import { AuthContext } from '../../../context/auth';
import { City } from "country-state-city";
import { useDispatch, useSelector } from 'react-redux';
import { moneyFormating } from '../../../utils/utilities/constants';
import toast from 'react-hot-toast';
import { useMutation } from '@tanstack/react-query';
import useOrder from '../../../utils/utilities/hooks/useOrder';
import { Riple } from 'react-loading-indicators';
import { Order } from '../../../sdks/order-v1/utils/DataSchemas';

import { emptyCart } from '../../../utils/redux/features/cartSlice';
import moment from 'moment';
import 'moment/locale/fr'  // without this line it didn't work
moment.locale('fr')
function OrderResume() {
  const navigate = useNavigate()
  let { client } = useOrder()
  const { sessionInfo, setUserInfo } = useContext(AuthContext)
  const dispatch = useDispatch();
  const [success, setSuccess] =  useState<boolean>(false)
  const [order, setOrder] =  useState<Order>()
  const { cart } = useSelector((state: any) => state.cart);
  const { 
    city,
    full_address,
    phone,
    note,
    fees } = useSelector((state: any) => state.order);

    const mutation = useMutation({
    mutationFn: async () => {
        return await client.createOrder({
          cart,
          shipping_address: {
            full_address,
            city,
            phone_number: phone
          },
          shipping_cost: fees,
          notes: note,
          total_price: totalPrice
          });
    },
    onSuccess: (data: any) => {
      setSuccess(true)
      setOrder(data?.order)
      dispatch(emptyCart())
    },
    onError: (data: any) => {
      toast.error("Une erreur s'est produite, veuillez réessayer" , {position: 'top-center'})
    },
  });
  useEffect(() => {
    if(!sessionInfo?.userInfo || !cart?.length) {
      navigate('/login')
    }
  }, [sessionInfo])

  const totalPrice = cart.reduce(
      (acc: any, item: any) => acc + item.qty * (item.promotion?.active ? item.promotion?.cost : item.price),
      0
  );

  console.log('success', success)


  
  return (
    <div className='--cart-page w-full flex justify-center' >
        <div className="wrapper">
        <Stepper
          active={4}
          />
        <div className="order">
          {success && <p className="mt-10 notice notice--success thankyou-order-received">Merci. Votre commande a été réçue par nos équipes.</p>}
          {success && <ul className="order-overview thankyou-order-details order_details">
            <li className="order-overview__order order">
              Numéro de commande:					<strong>{order?._id}</strong>
            </li>
            <li className="order-overview__date date">
              Date:					<strong>{moment(order?.created_at).format('DD MMMM YYYY hh:m:s')}</strong>
            </li>
            <li className="order-overview__email email">
              Email:						<strong>{order?.user?.email_address}</strong>
            </li>
            <li className="order-overview__total total">
              Total:					<strong><span className="Price-amount amount" >{moneyFormating(order?.total_price ?? 0)}</span></strong>
            </li>
            <li className="order-overview__payment-method method">
              Méthode de paiement:						<strong>Paiement à la livraison</strong>
            </li>
          </ul>}
          {!success && <section className="the-order-details">
            <h2 className="order-details__title">Détails de la commande</h2>
            <table className="table table--order-details shop_table order_details">
              <thead>
                <tr>
                  <th className="table__product-name product-name">Produit</th>
                  <th className="table__product-table product-total">Total</th>
                </tr>
              </thead>
              <tbody>

                {cart?.map((c: any, index: number) => {
                return <tr key={index} className="table__line-item order_item">
                        <td className="table__product-name product-name flex">
                          <p className='max-w-[200px] width500:max-w-[300px] bigTablet:max-w-[600px] truncate text-ellipsis'>{c?.title}&nbsp;</p> <strong className="product-quantity">×&nbsp;{c?.qty}</strong>
                        </td>
                        <td className="table__product-total product-total"> <span className="Price-amount amount" >{moneyFormating(c?.promotion?.active ? c?.promotion?.cost : c?.price)}</span></td>
                      </tr>
                    })
                }
                
              </tbody>
              <tfoot>
                <tr>
                  <th scope="row">Sous total:</th>
                  <td><span className="Price-amount amount"  >{moneyFormating(totalPrice)}</span></td>
                </tr>
                <tr>
                  <th scope="row">Frais de livraison:</th>
                  <td><span className="Price-amount amount"  >{moneyFormating(fees)}</span></td>
                </tr>
                {/* <tr>
                  <td colSpan={2} className="actions">
                    <div className="coupon">
                      <label htmlFor="coupon_code" className="screen-reader-text">Coupon:</label> <input type="text" name="coupon_code" className="input-text" id="coupon_code" placeholder="Coupon code" /> <button type="submit" className="button wp-element-button" name="apply_coupon" value="Apply coupon">Apply coupon</button>
                    </div>
                    
                    </td>
                </tr> */}
                {success && <tr>
                  <th scope="row">Methode de paiement:</th>
                  <td>Cash à la livraison</td>
                </tr>}
                <tr>
                  <th scope="row">Total:</th>
                  <td><span className="Price-amount amount" >{moneyFormating(totalPrice + fees)}</span></td>
                </tr>
                
              </tfoot>
            </table>
          </section>}
          {!success && <section className="customer-details">
            <h2 className="column__title">Adresse de livraison</h2>
            <address>
              {sessionInfo?.userInfo?.full_name}<br />
              {full_address}<br />
              {city}<br />
              Côte d'Ivoire
              <p className="customer-details--phone">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="16" height="16" color="#444444" fill="none">
                    <path d="M5 9C5 5.70017 5 4.05025 6.02513 3.02513C7.05025 2 8.70017 2 12 2C15.2998 2 16.9497 2 17.9749 3.02513C19 4.05025 19 5.70017 19 9V15C19 18.2998 19 19.9497 17.9749 20.9749C16.9497 22 15.2998 22 12 22C8.70017 22 7.05025 22 6.02513 20.9749C5 19.9497 5 18.2998 5 15V9Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
                    <path d="M11 19H13" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M9 2L9.089 2.53402C9.28188 3.69129 9.37832 4.26993 9.77519 4.62204C10.1892 4.98934 10.7761 5 12 5C13.2239 5 13.8108 4.98934 14.2248 4.62204C14.6217 4.26993 14.7181 3.69129 14.911 2.53402L15 2" stroke="currentColor" strokeWidth="1.5" strokeLinejoin="round" />
                </svg>
                {phone}</p>
              <p className="customer-details--email">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="16" height="16" color="#444444" fill="none">
                    <path d="M2 6L8.91302 9.91697C11.4616 11.361 12.5384 11.361 15.087 9.91697L22 6" stroke="currentColor" strokeWidth="1.5" strokeLinejoin="round" />
                    <path d="M2.01577 13.4756C2.08114 16.5412 2.11383 18.0739 3.24496 19.2094C4.37608 20.3448 5.95033 20.3843 9.09883 20.4634C11.0393 20.5122 12.9607 20.5122 14.9012 20.4634C18.0497 20.3843 19.6239 20.3448 20.7551 19.2094C21.8862 18.0739 21.9189 16.5412 21.9842 13.4756C22.0053 12.4899 22.0053 11.5101 21.9842 10.5244C21.9189 7.45886 21.8862 5.92609 20.7551 4.79066C19.6239 3.65523 18.0497 3.61568 14.9012 3.53657C12.9607 3.48781 11.0393 3.48781 9.09882 3.53656C5.95033 3.61566 4.37608 3.65521 3.24495 4.79065C2.11382 5.92608 2.08114 7.45885 2.01576 10.5244C1.99474 11.5101 1.99475 12.4899 2.01577 13.4756Z" stroke="currentColor" strokeWidth="1.5" strokeLinejoin="round" />
                </svg>
                {sessionInfo?.userInfo?.email_address}</p>
            </address>
          </section>}
          {!success && <div id="payment" className=" mt-5 checkout-payment">
            <ul className="wc_payment_methods payment_methods methods">
              {/* <li className="wc_payment_method payment_method_bacs">
                <input id="payment_method_bacs" type="radio" className="input-radio" name="payment_method" defaultValue="bacs" data-order_button_text />
                <label htmlFor="payment_method_bacs">
                  Direct bank transfer 	</label>
                <div className="payment_box payment_method_bacs" style={{display: 'none'}}>
                  <p>Make your payment directly into our bank account. Please use your Order ID as the payment reference. Your order will not be shipped until the funds have cleared in our account.</p>
                </div>
              </li>
              <li className="wc_payment_method payment_method_cheque">
                <input id="payment_method_cheque" type="radio" className="input-radio" name="payment_method" defaultValue="cheque" data-order_button_text />
                <label htmlFor="payment_method_cheque">
                  Check payments 	</label>
                <div className="payment_box payment_method_cheque" style={{display: 'none'}}>
                  <p>Please send a check to Store Name, Store Street, Store Town, Store State / County, Store Postcode.</p>
                </div>
              </li> */}
              <li className="wc_payment_method payment_method_cod">
                <input id="payment_method_cod" type="radio" checked className="input-radio" name="payment_method" defaultValue="cod" data-order_button_text />
                <label htmlFor="payment_method_cod">
                  Cash à la livraison 	</label>
                <div className="payment_box payment_method_cod">
                  <p>Payer lors de la livraison.</p>
                </div>
              </li>
            </ul>
            <div className="form-row place-order">
              <button type="button" className="button alt wp-element-button" 
               onClick={() => !mutation.isPending && mutation.mutate()}
              name="woocommerce_checkout_place_order" id="place_order">
                {
                  mutation?.isPending ? <Riple color="#fff" size="small" text="Loading..." textColor="" /> :'Valider la commande'
                }
              </button>
            </div>
          </div>}
        </div>

        </div>
        
      </div>
  )
}

export default OrderResume