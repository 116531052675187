import { Link, useNavigate, useParams } from 'react-router-dom'
import Stepper from '../../--components/--stepper'
import React, { useContext, useEffect, useState } from 'react'
import userUser from '../../../utils/utilities/hooks/useAuth';
import toast from 'react-hot-toast';
import { useMutation } from '@tanstack/react-query';
import { Riple } from 'react-loading-indicators';
import { AuthContext } from '../../../context/auth';

function PassChange() {

  const {accessKey} = useParams()
  const {changePassword } = useContext(AuthContext)
  const [sucess, setSuccess] = useState<boolean>(false)
  const [error, setError] = useState<boolean>(false)
  const [password, setPassword] = useState("");
  const [confirm_password, setConfirmpassword] = useState("");
  const mutation = useMutation({
    mutationKey: ['pass-change-key', accessKey],
    mutationFn: async () => {
      if(accessKey) {
        return await changePassword({
          change_pass_token: accessKey,
          password,
          confirm_password
        });
      }
    },
    onSuccess: (data, variables, context) => {
      setSuccess(true)
    },
    onError: (data: any) => {
      setError(true)
    },
  });

  return (
    <div className='--cart-page w-full flex justify-center' >
        <div className="wrapper">
        
          <div id="contents" role="main" className="col-lg-12 col-md-12 col-sm-12">
              <div className="post-300 page type-page status-publish hentry">
                <div className="entry-content">
                  <div className="woocommerce">
                    <div className="notices-wrapper" />
                    <div className="col2-set row" id="customer_login">
                      <div className="col-lg-6 col-md-6 col-sm-12">
                      {sucess ? <h2>Votre compte a été récupéré !</h2> : <h2>Enregistrer un nouveau mot de passe</h2>}
                        {!sucess && <form className="form form-login login" method="post">
                          <p className="form-row form-row-wide">
                            <label htmlFor="username">Mot de passe <span className="required">*</span></label>
                            <input 
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            type="password" className="input-text" name="username" id="username" />
                          </p>
                          <p className="form-row form-row-wide">
                            <label htmlFor="password">Confirmer le mot de passe <span className="required">*</span></label>
                            <input 
                            value={confirm_password}
                            onChange={(e) => setConfirmpassword(e.target.value)}
                            className="input-text" type="password" name="password" id="password" />
                          </p>
                          <p className="form-row">
                          <input 
                            disabled={mutation?.isPending
                              ||
                              (
                                password.trim() === "" ||
                                confirm_password.trim() === "" 
                              )
                          }
                            onClick={() => {
                              if(password !== confirm_password) {
                                toast.error("Les mots de passe sont différents" , {position: 'bottom-center'})
                              }else {
                                !mutation.isPending && mutation.mutate()
                              }
                            }}
                            type="button" className="button" defaultValue={!mutation.isPending ? "Enregistrer" : "Loading..."} /> 
                          </p>
                        </form>}
                        {sucess ? <p>Votre compte a été récupéré avec succès. Veuillez <Link to="/login" className='font-bold text-[#001c96]'>vous connecter</Link> et comencer vos achats.</p>: error ? <p>Une erreur s'est produite ou votre lien a expriré. Veuillez réprendre le procéssus.</p> : ''}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
          </div>

        </div>
        
      </div>
  )
}

export default PassChange