import { Link, useNavigate } from 'react-router-dom'
import Stepper from '../../--components/--stepper'
import React, { useContext, useEffect, useState } from 'react'
import userUser from '../../../utils/utilities/hooks/useAuth';
import toast from 'react-hot-toast';
import { useMutation } from '@tanstack/react-query';
import { AuthContext } from '../../../context/auth';

function Login() {
  const { sessionInfo, setUserInfo, signIn } = useContext(AuthContext)
  const [email_address, setEmailadress] = useState("");
  const [password, setPassword] = useState("");
  let { client } = userUser()
  const navigate =  useNavigate()

  const mutation = useMutation({
    mutationFn: async () => {
      return await signIn({
        email_address,
        password
      });
      
    },
    onSuccess: (data, variables, context) => {
      setEmailadress('')
      setPassword('')
      toast("Heureux de vous revoir !", {
        duration: 4000,
        position: 'top-center',
      
        // Styling
        style: {},
        className: '',
      
        // Custom Icon
        icon: '😊',
      
        // Change colors of success/error/loading icon
        iconTheme: {
          primary: '#000',
          secondary: '#fff',
        },
      
        // Aria
        ariaProps: {
          role: 'status',
          'aria-live': 'polite',
        },
      });

      setTimeout(() => {
          navigate('/')
      }, 500);
    },
    onError: (data: any) => {
      toast.error(data?.message , {position: 'bottom-center'})
    },
  });

  useEffect(() => {
    if(sessionInfo?.userInfo) {
      navigate('/')
    }
  }, [sessionInfo])

  return (
    <div className='--cart-page w-full flex justify-center' >
        <div className="wrapper">
        
          <div id="contents" role="main" className="col-lg-12 col-md-12 col-sm-12">
              <div className="post-300 page type-page status-publish hentry">
                <div className="entry-content">
                  <div className="woocommerce">
                    <div className="notices-wrapper" />
                    <div className="col2-set row" id="customer_login">
                      <div className="col-lg-6 col-md-6 col-sm-12">
                        <h2>Se connecter</h2>
                        <form className="form form-login login" method="post">
                          <p className="form-row form-row-wide">
                            <label htmlFor="username">Adresse email <span className="required">*</span></label>
                            <input 
                             value={email_address}
                             onChange={(e) => setEmailadress(e.target.value)}
                            type="text" className="input-text" name="username" id="username" />
                          </p>
                          <p className="form-row form-row-wide">
                            <label htmlFor="password">Mot de passe <span className="required">*</span></label>
                            <input 
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            className="input-text" type="password" name="password" id="password" />
                          </p>
                          <p className="lost_password">
                            <Link to="/pass-forgot">Mot de passe oublié?</Link>
                          </p>
                          <p className="form-row">
                          <input 
                            disabled={mutation?.isPending
                              ||
                              (
                                email_address.trim() === "" ||
                                password.trim() === "" 
                              )
                          }
                            onClick={() => {
                              !mutation.isPending && mutation.mutate()
                            }}
                            type="button" className="button" defaultValue={!mutation.isPending ? "Connexion" : "Loading..."} /> 
                          </p>
                          <p className="font-semibold">
                            Je viens d'arriver, je veux <Link to="/register" className='font-bold text-[#001c96]'>m'inscrire</Link>
                          </p>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
          </div>

        </div>
        
      </div>
  )
}

export default Login