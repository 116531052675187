import EmptyIllustration from '../../../layouts/--partials/--empty-illustration';
import Stepper from '../../--components/--stepper'
import { removeFromCart } from '../../../utils/redux/features/cartSlice';
import { moneyFormating } from '../../../utils/utilities/constants';
import React, { useContext } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { AuthContext } from '../../../context/auth';

function Cart() {

  const dispatch = useDispatch();
  const { sessionInfo, setUserInfo } = useContext(AuthContext)

  const { cart } = useSelector((state: any) => state.cart);

  const totalPrice = cart.reduce(
      (acc: any, item: any) => acc + item.qty * (item.promotion?.active ? item.promotion?.cost : item.price),
      0
    );

    const removeFromCartHandler = (data: any) => {
      dispatch(removeFromCart(data?._id));
    };
  return (
    <div className='--cart-page car-page w-full flex justify-center' >
        <div className="wrapper">
          <Stepper
          active={1}
          />

          {!cart?.length ? <EmptyIllustration/>: <></>}
          {cart?.length ? <form className="cart-form" action="" method="post">
            <table className="shop_table shop_table_responsive cart cart-form__contents" cellSpacing={0}>
              <thead> 
                <tr>
                  <th className="product-remove"><span className="screen-reader-text">Remove item</span></th>
                  <th className="product-thumbnail"><span className="screen-reader-text">Thumbnail image</span></th>
                  <th className="product-name font-semibold">Produit</th>
                  <th className="product-price">Prix</th>
                  <th className="product-quantity">Quantité</th>
                  <th className="product-subtotal">Sous total</th>
                </tr>
              </thead>
              <tbody>

              {cart?.map((c: any, index: number) => {
                      return <tr key={index} className="cart-form__cart-item cart_item">
                      <td className="product-remove">
                        <a href=""
                        onClick={(e: any) => {
                            e.preventDefault()
                            removeFromCartHandler(c)
                        }} className="remove" aria-label="Remove this item" data-product_id={259} data-product_sku>×</a>
                        </td>
                      <td className="product-thumbnail">
                      <Link to={`/product/${c?._id}`}><img width={300} height={300} src={c?.images && c?.images[0]?.url} /></Link>
                      </td>
                      <td className="product-name font-semibold" data-title="Produit:">
                        <p className='max-w-96 truncate text-ellipsis'><Link to={`/product/${c?._id}`}  >{c?.title}</Link></p>
                      </td>
                      <td className="product-price" data-title="Prix:">
                        <span className="Price-amount amount" >{moneyFormating(c?.promotion?.active ? c?.promotion?.cost : c?.price)}</span>
                        </td>
                      <td className="product-quantity" data-title="Quantité:">
                        <div className="quantity w-full flex justify-end bigTablet:justify-start items-center font-bold">
                        {c?.qty}
                        </div>
                      </td>
                      <td className="product-subtotal" data-title="Sous total:">
                        <span className="Price-amount amount" >{moneyFormating(c?.promotion?.active ? c?.qty * c?.promotion?.cost : c?.qty * c?.price)}</span>
                        </td>
                    </tr>
                  })
              }

                {/* <tr>
                  <td colSpan={6} className="actions">
                    <div className="coupon">
                      <label htmlFor="coupon_code" className="screen-reader-text">Coupon:</label> <input type="text" name="coupon_code" className="input-text" id="coupon_code" placeholder="Coupon code" /> <button type="submit" className="button wp-element-button" name="apply_coupon" value="Apply coupon">Apply coupon</button>
                    </div>
                    
                    </td>
                </tr> */}
              </tbody>
            </table>
          </form>: <></>}

          {cart?.length ? <div className="cart-collaterals">
            <div className="cart_totals">
              <h2>Résumé du panier</h2>
              <table cellSpacing={0} className="shop_table shop_table_responsive">
                <tbody>
                  <tr className="order-total">
                    <th>Total</th>
                    <td data-title="Total"><strong><span className="woocommerce-Price-amount amount" >{moneyFormating(totalPrice)}</span></strong> </td>
                  </tr>
                </tbody></table>
              <div className="wc-proceed-to-checkout">
                <Link to="/checkout" className="checkout-button button alt wc-forward wp-element-button">
                  Procéder à la commande</Link>
              </div>
            </div>
          </div>: <></>}

        </div>
        
      </div>
  )
}

export default Cart