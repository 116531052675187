import useProduct from '../../utils/utilities/hooks/useProduct';
import useOnClickOutSide from '../..//utils/utilities/onClickOutSide';
import React, { useRef, useState } from 'react'
import { useQuery } from '@tanstack/react-query';
import { Commet } from 'react-loading-indicators';
import { Product } from '../../sdks/product-v1/utils/DataSchemas';
import { moneyFormating } from '../../utils/utilities/constants';
import { Link } from 'react-router-dom';
import toast from 'react-hot-toast';

let timer: any = null
function ProductSearch() {
  let { client } = useProduct()

  const [page, setPage] = useState(1);
  const [limit, _] = useState(5);
  const [searchText, setSearchText] =
  useState("");
const [value, setValue] =
  useState("");

  const [meta, setMeta] = useState<any> (null)
  const {
    data: productData,
    isLoading
  } = useQuery({
    queryKey: ["products-search-data", page, limit, searchText],
    queryFn: async () => {
      if(searchText) {
         try {
          let result = await client.searchProduct({ page, limit, search: searchText})
          setMeta({
            hasNextPage : result?.hasNextPage,
            hasPrevPage : result?.hasPrevPage,
            limit : result?.limit,
            nextPage : result?.nextPage,
            page : result?.page,
            pagingCounter : result?.pagingCounter,
            prevPage : result?.prevPage,
            totalDocs : result?.totalDocs,
            totalPages : result?.totalPages
          })
          return result;
         } catch (error) {
          toast.error("Une erreur s'est produite, veuillez réessayer", {position: 'top-center'})
         }
        }
    },
  });
  const handleFilter = (e: any) => {
    setPage(1)
    setValue(e.target.value)
    if (timer) clearTimeout(timer)
    timer = setTimeout(async () => {
      try {
         setSearchText(e.target.value)
         setShowSearchAppend(true)
      } catch (error) {
        console.warn(error);
      }
    }, 1000)
  }
  const searchAppendRef = useRef<any>(null)

  const [showSearchAppend, setShowSearchAppend] = useState<boolean>(false);

  useOnClickOutSide(searchAppendRef, () => setShowSearchAppend(false))
  return (
    <div className='--product-search-field relative' >
      <button>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" color="#000000" fill="none">
          <path d="M14 14L16.5 16.5" stroke="currentColor" strokeWidth="1.5" strokeLinejoin="round" />
          <path d="M16.4333 18.5252C15.8556 17.9475 15.8556 17.0109 16.4333 16.4333C17.0109 15.8556 17.9475 15.8556 18.5252 16.4333L21.5667 19.4748C22.1444 20.0525 22.1444 20.9891 21.5667 21.5667C20.9891 22.1444 20.0525 22.1444 19.4748 21.5667L16.4333 18.5252Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
          <path d="M16 9C16 5.13401 12.866 2 9 2C5.13401 2 2 5.13401 2 9C2 12.866 5.13401 16 9 16C12.866 16 16 12.866 16 9Z" stroke="currentColor" strokeWidth="1.5" strokeLinejoin="round" />
      </svg>
      </button>

      <input type="text" placeholder='Recherche produits...' 
      value={value}
      onChange={handleFilter}
      />

      {showSearchAppend && <div  ref={searchAppendRef} className="--search-append absolute max-w-[500px] top-[50px] z-[1000] left-0 ">
        <div className="autocomplete-suggestions">
          {isLoading && <Commet color="#001c97db" size="small" text="Recherche" textColor="#fff" />}
          {productData?.results?.length ? <>

            {productData?.results?.map((rst: Product, index: number) => {
              return <div className="autocomplete-suggestion">
              <Link className="media-left" to={`/product/${rst?._id}`}>
                <img width={50} height={50} className="pull-left" src={rst?.images && rst?.images[0]?.url} />
                <div className="media-body">
                  <span className='truncate text-ellipsis'>{rst.title}</span>
                  <div className="search-meta">
                    <p className="price">
                      <span className="-Price-amount amount">
                        <bdi> {moneyFormating(rst?.promotion?.active ? rst?.promotion?.cost : rst?.price)}</bdi>
                      </span>
                    </p>
                  </div>
                </div>
              </Link>
            </div>

            })}

        {meta?.totalDocs &&<div className="flex w-full items-center justify-center py-5">

          {(meta?.totalDocs > limit) && <a href={`/search?q=${searchText}`} type='button' className="button text-[#001c97db]  text-[15px] font-bold "
            >
            Chargez plus
            </a >}
          </div>}
            
          </>: <></>}
        </div>
      </div>}
    </div>
  )
}

export default ProductSearch