import { AuthContext } from '../../../context/auth'
import Stepper from '../../--components/--stepper'
import React, { useContext, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { moneyFormating } from '../../../utils/utilities/constants'
import useProduct from '../../../utils/utilities/hooks/useProduct'
import toast from 'react-hot-toast'

function Wishlist() {

  const { sessionInfo, setUserInfo, signOut } = useContext(AuthContext)
  const navigate = useNavigate()

  let { client } = useProduct()
  useEffect(() => {
    if(!sessionInfo?.userInfo) {
      navigate('/')
    }
  }, [sessionInfo])


  const unlikeProduct = async (currentData: any) => {
    try {
      const result = await client.addToWish(currentData?._id)
      setUserInfo({...sessionInfo?.userInfo, wish_products: result?.user?.wish_products})
    } catch (error) {
        toast.error("Une erreur s'est produite, veuillez réessayer !");
    }
}
  return (
    <div className='--cart-page car-page w-full flex justify-center' >
        <div className="wrapper">
        <table className="shop_table cart wishlist_table wishlist_view traditional responsive   " data-pagination="no" data-per-page={5} data-page={1} data-id={5} data-token="OPTXYX2U4HQR">
          <thead>
            <tr>
              <th className="product-thumbnail" />
              <th className="product-name">
                <span className="nobr">
                  Produit			</span>
              </th>
              <th className="product-price">
                <span className="nobr">
                  Prix				</span>
              </th>
              <th className="product-stock-status">
                <span className="nobr">
                  Etat de stock				</span>
              </th>
              <th className="product-add-to-cart">
                <span className="nobr">
                </span>
              </th>
            </tr>
          </thead>
          <tbody className="wishlist-items-wrapper">
          {sessionInfo?.userInfo?.wish_products?.map((c: any, index: number) => {
            return <tr id="yith-wcwl-row-260" data-row-id={260} key={index} >
              <td className="product-thumbnail">
                <div>
                          <a href="" 
                          onClick={(e: any) => {e.preventDefault() ; unlikeProduct(c)} }
                          className="remove remove_from_wishlist" title="Remove this product">×</a>
                </div>
                <Link to={`/product/${c?._id}`}>
                  <img width={300} height={300} src={c?.images && c?.images[0]?.url}/> </Link>
              </td>
              <td className="product-name" data-title="Produit:">
                <Link to={`/product/${c?._id}`}>
                <p className='max-w-[300px] bigTablet:max-w-[600px] truncate text-ellipsis'>{c?.title}&</p>						</Link>
              </td>
              <td className="product-price" data-title="Prix:">
              {c?.promotion?.active && <del aria-hidden="true"><span className="-Price-amount amount"><bdi>{moneyFormating(c?.price)}</bdi></span></del>}
              <ins><span className="-Price-amount amount"><bdi>{moneyFormating(c?.promotion?.active ? c?.promotion?.cost : c?.price)}</bdi></span></ins>
              </td>
              <td className={"product-stock-status"} data-title="Etat de stock">
                <span className={`wishlist-in-stock ${c?.status === 'in-stock' ? 'in-stock': 'out-stock'}`}>
                {c?.status === 'in-stock' ? 'En stock': 'En rupture de stock'}
                </span>
              </td>
              <td className="product-add-to-cart" data-title="Action:">
                {/* Date added */}
                {/* Add to cart button */}
                <Link to={`/product/${c?._id}`}  className="wp-element-button product_type_simple add_to_cart_button ajax_add_to_cart add_to_cart alt" >Détails</Link>							
                {/* Change wishlist */}
                {/* Remove from wishlist */}
              </td>
            </tr>})}
          </tbody>
        </table>


        </div>
        
      </div>
  )
}

export default Wishlist